
import { defineComponent, defineAsyncComponent, PropType, computed } from 'vue';

/** Interfaces */
import {
  CovenantI,
  GetReferenceAndAgreementI,
} from '@/interfaces/placetopay.interface';

export default defineComponent({
  name: 'ModalPaymentChannels',
  components: {
    NewModal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    customMargin: {
      type: Boolean,
      required: false,
      default: true,
    },
    paymentChannels: {
      type: Object as PropType<GetReferenceAndAgreementI>,
      required: true,
      default: Object.create({}) as GetReferenceAndAgreementI,
    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close');
    };

    const existCovenantAttr = computed(
      (): boolean => props.paymentChannels?.existCovenantAttr
    );

    const isNpl = computed((): boolean => props.paymentChannels?.isNpl);

    const setHeight = computed(() => {
      return existCovenantAttr.value
        ? 'bg-white rounded-lg bg-lightBackground h-98 p-4 m-2 md:min-w-4/12 overflow-x-hidden lg:p-6'
        : 'bg-white rounded-lg bg-lightBackground h-5/6 p-4 m-2 md:min-w-4/12 overflow-x-hidden lg:p-6';
    });

    const channels = computed<CovenantI[]>(
      () => props.paymentChannels.covenant as CovenantI[]
    );

    return { close, setHeight, existCovenantAttr, channels, isNpl };
  },
});
